import React, { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import AutoSuggestList from "./AutoSuggestList.tsx";
import throttle from "lodash.throttle";
import { useGeolocation } from "../../context/GeolocationContext.tsx";
import { IconChevronDown, IconSearch } from "@tabler/icons-react";
import getProductSearchSuggestions, {
  ProductSearchSuggestion,
} from "../../api/getProductSearchSuggestions.ts";
import { twMerge } from "tailwind-merge";
import BackButton from "../BackButton.tsx";

const GlobalHeader = () => {
  const headerContainerRef = useRef<HTMLDivElement>(null);
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();
  const location = useLocation();
  const { geolocation } = useGeolocation();
  const storedRecentSearches = localStorage.getItem("recentSearches") || "[]";

  const [query, setQuery] = useState("");
  const [headerHeight, setHeaderHeight] = useState(0);
  const [suggestions, setSuggestions] = useState<ProductSearchSuggestion[]>([]);

  useEffect(() => {
    setQuery(searchParams.get("q") || "");
  }, [searchParams]);

  useEffect(() => {
    if (!headerContainerRef.current) return;
    const observer = new ResizeObserver((entries) => {
      setHeaderHeight(entries[0].borderBoxSize[0].blockSize);
    });
    observer.observe(headerContainerRef.current);
  }, [headerContainerRef]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const throttledFetchSuggestions = useCallback(
    throttle(async (value: string) => {
      if (value.length > 0) {
        try {
          const data = await getProductSearchSuggestions(geolocation, value);
          setSuggestions(data);
        } catch (error) {
          console.error("Error fetching suggestions:", error);
        }
      } else {
        setSuggestions([]);
      }
    }, 1000),
    [geolocation],
  );

  useEffect(() => {
    if (!location.state?.query) {
      return;
    }

    setQuery(location.state.query);

    if (location.pathname === "/search") {
      setSuggestions([]);
      throttledFetchSuggestions(location.state.query);
    }
  }, [location, throttledFetchSuggestions]);

  const handleInputChange = useCallback(
    (e) => {
      if (!e.target?.value) {
        setSuggestions([]);
      }

      if (e.target.value?.length > 1 && geolocation) {
        throttledFetchSuggestions(e.target.value);
      }

      setQuery(e.target?.value);
    },
    [throttledFetchSuggestions, geolocation],
  );

  const triggerSearch = useCallback(
    (query: string) => {
      const recentSearches = JSON.parse(storedRecentSearches);
      if (query && !recentSearches.includes(query)) {
        recentSearches.push(query);
        if (recentSearches.length > 7) recentSearches.shift();
        localStorage.setItem("recentSearches", JSON.stringify(recentSearches));
      }
      setSuggestions([]);
      navigate(`/search-results?q=${query}`, { replace: true });
    },
    [navigate, storedRecentSearches],
  );

  const handleSearchButtonClick = useCallback(() => {
    if (window.location.pathname === "search") return;
    navigate("/search", { state: { query } });
  }, [navigate, query]);

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      setSuggestions([]);
      triggerSearch(query);
    },
    [query, triggerSearch],
  );

  const handleSuggestionClick = useCallback(
    (query) => {
      setQuery(query);
      setSuggestions([]);
      triggerSearch(query);
    },
    [triggerSearch],
  );

  const handleLocationClick = useCallback(() => {
    navigate("/geolocation");
  }, [navigate]);

  return (
    <div className="fixed mx-auto w-full max-w-[1200px] shadow">
      <div className={twMerge("bg-bg box-border flex w-full flex-col items-center")}>
        <div className="flex w-full flex-col gap-0 px-4 py-4" ref={headerContainerRef}>
          {/* {location.pathname === "/" && ( */}
            <button onClick={handleLocationClick} className="flex w-full flex-col">
              <div className="text-text-light flex items-center justify-center text-left text-sm">
                Deliver to
                <IconChevronDown className="ml-1 h-4 w-4" />
              </div>
              <div className="flex items-center justify-left text-left text-l font-bold w-full gap-2">
                <div className="truncate">
                  {geolocation ? geolocation.name : "Select Location"}
                </div>
              </div>
            </button>
          {/* )} */}
          {location.pathname !== "/geolocation" && (
            <div className="mt-2 flex w-full gap-4">
              <BackButton />
              <form className="relative grow" onSubmit={handleSubmit}>
                <IconSearch className="absolute left-2.5 top-[13px] h-4 w-4 text-slate-950" />
                <input
                  type="search"
                  autoFocus={location.pathname === "/search"}
                  value={query}
                  onClick={handleSearchButtonClick}
                  onChange={handleInputChange}
                  className="w-full cursor-pointer rounded-xl border border-action bg-white p-2 ps-8 focus:cursor-auto focus:outline-none"
                  placeholder="Search for products..."
                />
              </form>
            </div>
          )}
        </div>
        {query && location.pathname === "/search" ? (
          <AutoSuggestList
            query={query}
            suggestions={suggestions}
            onClick={handleSuggestionClick}
            headerHeight={headerHeight}
          />
        ) : null}
      </div>
    </div>
  );
};

export default GlobalHeader;
