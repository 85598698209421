// PrivacyPolicy.js
import React from 'react';

const PrivacyPolicyPage = () => {
  return (
    <div className="privacy-container">
      <h1 className="privacy-title">Privacy Policy</h1>
      <div className="privacy-content">
        <p className="effective-date">Effective Date: December 27, 2024</p>

        <section className="privacy-section">
          <h2>Introduction</h2>
          <p>
            Quick Compare ("we", "our", or "us") is committed to protecting your privacy. 
            This Privacy Policy explains how we collect, use, and safeguard your information 
            when you use our website quickcompare.in and our services.
          </p>
        </section>

        <section className="privacy-section">
          <h2>Information We Collect</h2>
          <div className="info-types">
            <h3>Personal Information</h3>
            <ul>
              <li>Device Location</li>
            </ul>
          </div>
        </section>

        <section className="privacy-section">
          <h2>How We Use Your Information</h2>
          <ul>
            <li>To provide and maintain our service</li>
            <li>To notify you about changes to our service</li>
            <li>To provide customer support</li>
            <li>To gather analysis or valuable information to improve our service</li>
          </ul>
        </section>

        <section className="privacy-section">
          <h2>Data Security</h2>
          <p>
            We implement appropriate security measures to protect your personal information. 
            However, no method of transmission over the internet is 100% secure, and we 
            cannot guarantee absolute security.
          </p>
        </section>

        <section className="privacy-section">
          <h2>Contact Us</h2>
          <p>
            If you have any questions about this Privacy Policy, please contact us at:
            <br />
            Email: connect@quickcompare.in
            <br />
            <a href='htpps://quickcompare.in'>Website: htpps://quickcompare.in</a>
          </p>
        </section>
      </div>
    </div>
  );
};

// Styles
const styles = `
.privacy-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  font-family: Arial, sans-serif;
  line-height: 1.6;
}

.privacy-title {
  color: #333;
  text-align: center;
  margin-bottom: 2rem;
  font-size: 2.5rem;
}

.privacy-content {
  background: #fff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.effective-date {
  color: #666;
  font-style: italic;
  margin-bottom: 2rem;
}

.privacy-section {
  margin-bottom: 2rem;
}

.privacy-section h2 {
  color: #2c3e50;
  margin-bottom: 1rem;
  padding-bottom: 0.5rem;
  border-bottom: 2px solid #eee;
}

.privacy-section h3 {
  color: #34495e;
  margin: 1rem 0;
}

.privacy-section ul {
  padding-left: 1.5rem;
}

.privacy-section li {
  margin-bottom: 0.5rem;
}

.info-types {
  background: #f8f9fa;
  padding: 1rem;
  border-radius: 4px;
  margin: 1rem 0;
}

@media (max-width: 768px) {
  .privacy-container {
    padding: 1rem;
  }
  
  .privacy-title {
    font-size: 2rem;
  }
}
`;

// Add styles to document
const styleSheet = document.createElement("style");
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);

export default PrivacyPolicyPage;
