import React from "react";
import GeolocationProvider from "./context/GeolocationContext.tsx";
import AuthenticationProvider from "./context/AuthenticationContext.tsx";
import { initFirebase } from "./services/firebase.ts";
import Router from "./Router.tsx";

initFirebase();

const App = () => {
  return (
    <div className="relative mx-auto h-screen bg-bg">
      <div className="relative mx-auto h-screen max-w-[1200px] shadow">
        <GeolocationProvider>
          <AuthenticationProvider>
            <Router />
          </AuthenticationProvider>
        </GeolocationProvider>
      </div>
    </div>
  );
};

export default App;
